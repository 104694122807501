<template>
    <div>
        <canvas :id="canvasId" />
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    props: {
        canvasId: { type: String, required: true },
        data: { type: Object },
        title: { type: String },
    },

    data: function() {
        return {
            chart: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: !!this.title,
                        text: this.title,
                    },
                    tooltip: {
                        callbacks: {
                            title: () => null,
                            label: ctx => {
                                const duree = this.$options.filters.duration(
                                    ctx.parsed.x,
                                );
                                return `${ctx.parsed.y} session(s) d'environ ${duree}`;
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        display: true,
                        type: "linear",
                        beginAtZero: true,
                        bounds: "data",
                        ticks: {
                            callback: value => {
                                return this.$options.filters.duration(value);
                            },
                            stepSize: 60000,
                            maxTicksLimit: 8,
                        },
                        grid: {
                            offset: false,
                        },
                        offset: false,
                    },
                    y: {
                        display: true,
                    },
                },
            },
        };
    },

    watch: {
        data() {
            this.updateChartData();
        },
    },

    methods: {
        updateChartData() {
            this.chart.data = {
                datasets: [
                    {
                        backgroundColor: "#00817b",
                        data: Object.entries(this.data).map(([k, v]) => ({
                            x: k,
                            y: v,
                        })),
                    },
                ],
            };
            this.chart.update();
        },
    },

    mounted() {
        this.chart = new Chart(document.getElementById(this.canvasId), {
            type: "bar",
            options: this.chartOptions,
            data: {},
        });
        this.updateChartData();
    },
};
</script>
