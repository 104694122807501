import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import axios from "axios";

export default {
    async createXLSX(data) {
        try {
            // Téléchargement du template (public assets)
            let response = await axios.get(`/templates/ExportStats.xlsx`, {
                responseType: "arraybuffer",
            });

            // Ouverture du template
            const workbook = await XlsxPopulate.fromDataAsync(response.data);

            // Insertion des infos générales
            const worksheet_1 = workbook.sheet("Données - Infos");

            worksheet_1.cell("C2").value(new Date(data.from));
            worksheet_1.cell("C3").value(new Date(data.to));
            worksheet_1.cell("C5").value(new Date());
            worksheet_1
                .cell("C8")
                .value(data.installations.map(i => [i.name, i.type, i.place]));

            // Insertion des stats
            if (data.stats) {
                const {
                    activity,
                    touches,
                    sessions,
                    duration,
                    fiches,
                } = data.stats;

                // Activité
                if (activity) {
                    const worksheet_2 = workbook.sheet("Données - Activité");
                    worksheet_2.cell("C2").value(activity.days);
                    worksheet_2.cell("E2").value(activity.total);
                    worksheet_2.cell("D5").value(
                        new Array(7).fill().reduce((acc, k, i) => {
                            acc[i] = [activity.perDay[i] || 0];
                            return acc;
                        }, []),
                    );
                    worksheet_2.cell("D14").value(
                        new Array(24).fill().reduce((acc, k, i) => {
                            acc[i] = [activity.perHour[i] || 0];
                            return acc;
                        }, []),
                    );
                }

                // Interactions et sessions
                const worksheet_3 = workbook.sheet("Données - Interactions");
                if (touches && sessions) {
                    worksheet_3.cell("C2").value(
                        Object.keys(touches)
                            .map(day => {
                                return [
                                    new Date(day),
                                    touches[day],
                                    sessions[day] || 0,
                                    sessions[day] > 0
                                        ? touches[day] / sessions[day]
                                        : 0,
                                ];
                            })
                            .sort((a, b) => a[0] - b[0]),
                    );
                }
                if (duration && duration.length > 0) {
                    worksheet_3
                        .cell("I2")
                        .value(duration.map(r => [r / 86400000]));
                }

                // Fiches
                if (fiches && fiches.length > 0) {
                    const worksheet_4 = workbook.sheet("Données - Fiches");

                    worksheet_4
                        .cell("C2")
                        .value(fiches.map(f => [f.name, f.touch, f.id]));
                }
            }

            // Export
            const blob = await workbook.outputAsync();
            saveAs(blob, "stats.xlsx");
        } catch (error) {
            throw "Erreur de génération du fichier Excel";
        }
    },
};
