<template>
    <div :id="id" class="k-card-4">
        <div class="header">Activité</div>

        <ChartDonutPercent
            class="donut mt-3"
            :canvas-id="id + '-canvas-activity'"
            :value="activity"
            :total="duration"
        />

        <div class="text-center">
            <b class="text-kalkin-2">{{ activity }}</b> jour(s) actif(s) sur
            {{ duration }}
        </div>

        <div class="mt-3">
            Activité selon
            <span class="text-kalkin-2">le jour de la semaine</span> :
        </div>
        <ChartBarVertical
            :canvas-id="id + '-canvas-weekday'"
            :data="touchesWeekday"
        />

        <div class="mt-3">
            Activité selon <span class="text-kalkin-2">l'heure du jour</span> :
        </div>
        <ChartBarVertical
            :canvas-id="id + '-canvas-hour'"
            :data="touchesHour"
        />

        <!-- <pre>{{ $data }}</pre> -->
    </div>
</template>

<script>
import ChartDonutPercent from "@/components/model/stats/chart/ChartDonutPercent";
import ChartBarVertical from "@/components/model/stats/chart/ChartBarVertical";
import { debounce } from "lodash";
import { fr } from "date-fns/locale";

const HOUR_GROUPS = [
    { key: "0h-6h", min: 0, max: 5 },
    { key: "6h-9h", min: 6, max: 8 },
    { key: "9h-12h", min: 9, max: 11 },
    { key: "12h-14h", min: 12, max: 13 },
    { key: "14h-17h", min: 14, max: 16 },
    { key: "17h-20h", min: 17, max: 19 },
    { key: "20h-0h", min: 20, max: 23 },
];

export default {
    components: {
        ChartDonutPercent,
        ChartBarVertical,
    },

    props: {
        id: { type: String, required: true },
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
        duration: { type: Number, required: true },
    },

    data: function() {
        return {
            canvasId: this.id + "-canvas",
            activity: "?",
            touchesWeekday: {},
            touchesHour: {},
        };
    },

    watch: {
        $props: {
            async handler() {
                await this.fetchData();
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        fetchData: debounce(async function() {
            if (this.installations.length > 0) {
                this.activity = await this.$store.dispatch(
                    "stats/getActivity",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );

                // Récupération des jours et formattage
                let rawDays = await this.$store.dispatch(
                    "stats/getTouchesPerWeekday",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                let mapDays = new Array(7).fill().reduce((acc, k, i) => {
                    acc[fr.localize.day((i + 1) % 7)] = 0;
                    return acc;
                }, {});
                for (const [k, v] of Object.entries(rawDays)) {
                    mapDays[fr.localize.day((+k + 1) % 7)] += v;
                }
                this.touchesWeekday = mapDays;

                // Récupération des heures et formattage
                let rawHours = await this.$store.dispatch(
                    "stats/getTouchesPerHour",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                let mapHours = HOUR_GROUPS.reduce((acc, cur) => {
                    acc[cur.key] = 0;
                    return acc;
                }, {});
                for (const [h, v] of Object.entries(rawHours)) {
                    let group = HOUR_GROUPS.find(
                        row => h >= row.min && h <= row.max,
                    );
                    if (group) {
                        mapHours[group.key] += v;
                    }
                }
                this.touchesHour = mapHours;

                this.$emit("load", {
                    days: this.activity,
                    total: this.duration,
                    perDay: rawDays,
                    perHour: rawHours,
                });
            }
        }, 100),
    },
};
</script>

<style lang="scss" scoped>
.donut {
    width: 180px;
    margin: 0 auto;
}
</style>
