<template>
    <canvas :id="canvasId" />
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    PalettePrimary6Generic,
    PaletteSecondary6Generic,
} from "@/tools/color";

export default {
    props: {
        canvasId: { type: String, required: true },
        data: { type: Object },
    },

    data: function() {
        return {
            canvas: null,
            chart: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                parsing: {
                    yAxisKey: "value",
                },
                layout: {
                    autoPadding: true,
                    padding: { left: 15 },
                },
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                            boxWidth: 20,
                        },
                    },
                    datalabels: {
                        backgroundColor: function(context) {
                            return context.dataset.backgroundColor;
                        },
                        borderColor: "white",
                        borderRadius: 8,
                        borderWidth: 1,
                        color: "white",
                        anchor: "center",
                        align: "end",
                        font: {
                            size: 14,
                            weight: "bold",
                        },
                        formatter: function(value) {
                            return `${value.label}%`;
                        },
                    },
                },
                onClick: evt => {
                    const [point] = this.chart.getElementsAtEventForMode(
                        evt,
                        "point",
                        { intersect: true },
                        true,
                    );
                    if (point) {
                        this.$emit("click", point.index);
                    }
                },
            },
        };
    },

    watch: {
        data() {
            this.updateChartData();
        },
    },

    methods: {
        updateChartData() {
            const raw = Object.values(this.data);
            const total = raw.reduce((a, b) => a + b, 0);
            const data = raw.map(r => {
                return {
                    value: r,
                    label: Math.round((r / total) * 100),
                };
            });

            this.chart.data = {
                labels: Object.keys(this.data),
                datasets: [
                    {
                        backgroundColor: [
                            ...PaletteSecondary6Generic,
                            ...PalettePrimary6Generic,
                        ],
                        data,
                    },
                ],
            };
            this.chart.update();
        },
    },

    mounted() {
        this.canvas = document.getElementById(this.canvasId);
        this.chart = new Chart(this.canvas, {
            type: "pie",
            options: this.chartOptions,
            data: {},
            plugins: [ChartDataLabels],
        });
        this.updateChartData();
    },
};
</script>
