// Palettes generated using https://mycolor.space/

module.exports = {
    PalettePrimary6Generic: [
        "#00817B",
        "#289C81",
        "#54B681",
        "#85CF7B",
        "#BCE574",
        "#F9F871",
    ],
    PaletteSecondary6Generic: [
        "#92BB3F",
        "#4AAA5E",
        "#009475",
        "#007B7D",
        "#086172",
        "#2F4858",
    ],
};
