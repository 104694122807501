<template>
    <div :style="'height:' + height + 'px'">
        <canvas :id="canvasId" />
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    props: {
        canvasId: { type: String, required: true },
        data: { type: Object },
    },

    data: function() {
        return {
            chart: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                indexAxis: "y",
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: true,
                        type: "category",
                        ticks: {
                            autoSkip: false,
                        },
                    },
                },
            },
        };
    },

    watch: {
        data() {
            this.updateChartData();
        },
    },

    computed: {
        height() {
            return Object.keys(this.data).length * 20;
        },
    },

    methods: {
        updateChartData() {
            this.chart.data = {
                labels: Object.keys(this.data),
                datasets: [
                    {
                        backgroundColor: "#00817b",
                        data: Object.values(this.data),
                    },
                ],
            };
            this.chart.update();
        },
    },

    mounted() {
        this.chart = new Chart(document.getElementById(this.canvasId), {
            type: "bar",
            options: this.chartOptions,
            data: {},
        });
    },
};
</script>
