<template>
    <div class="position-relative">
        <div class="percentage">
            <h2 class="m-0">{{ percentage }}%</h2>
        </div>
        <canvas :id="canvasId" />
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    props: {
        canvasId: { type: String, required: true },
        value: { type: [Number, String] },
        total: { type: [Number, String] },
    },

    data: function() {
        return {
            canvas: null,
            chart: null,
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                cutout: "80%",
            },
        };
    },

    computed: {
        percentage() {
            try {
                return Math.round((this.value / this.total) * 100);
            } catch (error) {
                return "?";
            }
        },
    },

    watch: {
        value() {
            this.updateChartData();
        },
    },

    methods: {
        updateChartData() {
            this.chart.data = {
                datasets: [
                    {
                        data: [this.value, this.total - this.value],
                        backgroundColor: ["#00817b", "#ffffff"],
                        weight: 20,
                    },
                ],
            };
            this.chart.update();
        },
    },

    mounted() {
        this.canvas = document.getElementById(this.canvasId);
        this.chart = new Chart(this.canvas, {
            type: "doughnut",
            options: this.chartOptions,
            data: {},
        });
        this.updateChartData();
    },
};
</script>

<style lang="scss" scoped>
.percentage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>
