<template>
    <div :id="id" class="k-card-4">
        <div class="header">Menus visités</div>
        <div>
            <small>
                Ce diagramme interactif permet de consulter la distribution des
                interactions lors de la navigation pour identifier quels sont
                les menus qui ont été les plus visités
            </small>
        </div>

        <div class="k-stats-menu-div" v-if="currentMenu && currentMenu.name">
            <div class="k-stats-menu-tree">
                <template v-if="ancestorsMenus.length">
                    <div
                        v-for="(menu, i) in ancestorsMenus"
                        :key="menu.id"
                        class="ancestor"
                        :style="`margin-left:${i * padding}px`"
                        @click="onAncestorSelected(menu.id)"
                    >
                        {{ menu.name }} <b-badge>{{ menu.touch }}</b-badge>
                    </div>
                </template>

                <div
                    class="parent disabled"
                    :style="`margin-left:${ancestorsMenus.length * padding}px`"
                >
                    {{ currentMenu.name }}
                    <b-icon-geo-alt-fill class="ml-1" />
                    <b-badge>{{ currentMenu.touch }}</b-badge>
                </div>

                <div
                    v-for="child in currentMenu.children"
                    :key="child.id"
                    class="child"
                    :class="{ disabled: !child.children }"
                    :style="
                        `margin-left:${(ancestorsMenus.length + 1) * padding}px`
                    "
                    @click="child.children && onChildSelected(child.id)"
                >
                    {{ child.name }}
                    <b-icon-plus-lg class="ml-1" v-if="child.children" />
                    <b-badge>
                        {{ child.touch }}
                    </b-badge>
                </div>
            </div>

            <div class="k-stats-menu-pie">
                <ChartPie
                    :canvas-id="id + '-canvas-nav'"
                    :data="pieData"
                    @click="onPieClick"
                />
            </div>
        </div>
        <div class="text-center" v-else>
            <i>Aucune information disponible</i>
        </div>
    </div>
</template>

<script>
import ChartPie from "@/components/model/stats/chart/ChartPie";
import { debounce } from "lodash";

export default {
    components: {
        ChartPie,
    },

    props: {
        id: { type: String, required: true },
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
    },

    data: function() {
        return {
            padding: 6,
            navigationData: {},
            menuHistory: [],
            currentMenu: null,
        };
    },

    computed: {
        ancestorsMenus() {
            return this.menuHistory.slice(0, -1);
        },

        pieData() {
            if (this.currentMenu && this.currentMenu.children) {
                return this.currentMenu.children.reduce((acc, cur) => {
                    acc[cur.name] = cur.touch;
                    return acc;
                }, {});
            } else {
                return {};
            }
        },
    },

    watch: {
        $props: {
            async handler() {
                await this.fetchData();
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        fetchData: debounce(async function() {
            if (this.installations.length > 0) {
                this.navigationData = await this.$store.dispatch(
                    "stats/getTouchesOnMenus",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.menuHistory = [];
                if (this.navigationData) {
                    this.menuHistory = [this.navigationData];
                    this.currentMenu = this.navigationData;
                }
            }
        }, 100),

        onAncestorSelected(menuId) {
            const idx = this.menuHistory.findIndex(item => item.id === menuId);
            if (idx >= 0) {
                this.currentMenu = this.menuHistory[idx];
                this.menuHistory.splice(idx + 1);
            }
        },

        onChildSelected(childId) {
            let menu = this.currentMenu.children.find(m => m.id === childId);
            if (menu) {
                this.currentMenu = menu;
                this.menuHistory.push(menu);
            }
        },

        onPieClick(index) {
            if (this.currentMenu && this.currentMenu.children) {
                const target = this.currentMenu.children[index];
                if (target.children) {
                    this.onChildSelected(target.id);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.k-stats-menu-div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.k-stats-menu-tree {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1px;

    min-width: 250px;
    width: 250px;
    padding: 10px 0 10px 10px;
    border-radius: 8px;
    box-shadow: 3px 3px 6px #00000020, -3px -3px 6px #00000020;
    color: #fff;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 1px 10px;
        border: 2px solid transparent;
        border-radius: 8px 0 0 8px;

        &:not(.disabled) {
            cursor: pointer;
            &:hover {
                padding-left: 12px;
                border-color: #00000055;
            }
        }
        .badge {
            position: absolute;
            right: -38px;
            width: 35px;
            background-color: #bbb;
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .ancestor {
        background: darken($primary-color, 10);
    }
    .parent {
        background: $primary-color;
    }
    .child {
        background: lighten($primary-color, 10);
        .badge {
            background: lighten($primary-color, 10);
        }
    }
    .child.disabled {
        background: lighten($primary-color, 15);
        .badge {
            background: lighten($primary-color, 15);
        }
    }
}

.k-stats-menu-pie {
    min-width: 250px;
    min-height: 200px;
    flex: 1;
}
</style>
