<template>
    <div :id="id" class="k-card-4">
        <div class="header">Durée des sessions</div>
        <div>
            <div>
                <small>
                    Durée moyenne des sessions :
                </small>
                <b class="text-kalkin-2">{{ avgDuration | duration }}</b>
            </div>
            <div>
                <small>
                    Session la plus longue :
                </small>
                <b class="text-kalkin-2">{{ maxDuration | duration }}</b>
            </div>
        </div>
        <div class="flex-grow-1"></div>
        <div v-if="sessionsDuration.length">
            <ChartBarDuration :canvas-id="canvasId" :data="graphData" />
        </div>
    </div>
</template>

<script>
import ChartBarDuration from "@/components/model/stats/chart/ChartBarDuration";
import { debounce } from "lodash";

export default {
    components: {
        ChartBarDuration,
    },

    props: {
        id: { type: String, required: true },
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
    },

    data: function() {
        return {
            canvasId: this.id + "-canvas",
            sessionsDuration: [],
        };
    },

    watch: {
        $props: {
            async handler() {
                await this.fetchData();
            },
            deep: true,
            immediate: true,
        },
    },

    computed: {
        maxDuration() {
            if (this.sessionsDuration.length > 0) {
                return Math.max(...this.sessionsDuration);
            } else {
                return 0;
            }
        },

        avgDuration() {
            if (this.sessionsDuration.length > 0) {
                return (
                    this.sessionsDuration.reduce((p, c) => p + c, 0) /
                    this.sessionsDuration.length
                );
            } else {
                return 0;
            }
        },

        graphData() {
            return this.sessionsDuration.reduce((acc, cur) => {
                const key = Math.ceil(cur / 20000) * 20000;
                if (acc[key]) {
                    acc[key]++;
                } else {
                    acc[key] = 1;
                }
                return acc;
            }, {});
        },
    },

    methods: {
        fetchData: debounce(async function() {
            if (this.installations.length > 0) {
                this.sessionsDuration = await this.$store.dispatch(
                    "stats/getSessionsDuration",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.sessionsDuration.sort((i, j) => i - j);
                this.$emit("load", this.sessionsDuration);
            }
        }, 100),
    },
};
</script>
