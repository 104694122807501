<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                Statistiques détaillées
            </h1>
            <h2>{{ pageName }}</h2>
        </div>
        <b-tabs class="k-page-body" content-class="mt-3" align="left">
            <b-tab title="Consultation" active>
                <div class="k-flex-row mb-2">
                    <b-form inline>
                        Période
                        <b-form-select
                            class="mx-2"
                            v-model="periodSelected"
                            :options="periodOptions"
                        />
                        du
                        <DatePicker
                            class="mx-2"
                            v-if="periodSelected == 'custom'"
                            v-model="periodCustom"
                            type="date"
                            range
                            range-separator=" au "
                            placeholder="Sélectionner les dates de la période"
                            format="DD/MM/YYYY"
                            value-type="YYYY-MM-DD"
                            :clearable="false"
                        />
                        <template v-else>
                            {{ dateFrom | dateFromISO(true) }} au
                            {{ dateTo | dateFromISO(true) }}
                        </template>
                    </b-form>

                    <b-button size="sm" @click="exportAsXLSX">
                        <b-icon-download /> Exporter les données
                    </b-button>
                </div>
                <div id="tab-consult-grid">
                    <CardTouches
                        id="card-touches"
                        :project-id="projectId"
                        :installations="installations"
                        :from="dateFrom"
                        :to="dateTo"
                        @load="stats['touches'] = $event"
                    />
                    <CardSessions
                        id="card-sessions"
                        :project-id="projectId"
                        :installations="installations"
                        :from="dateFrom"
                        :to="dateTo"
                        @load="stats['sessions'] = $event"
                    />
                    <CardDuration
                        id="card-duration"
                        :project-id="projectId"
                        :installations="installations"
                        :from="dateFrom"
                        :to="dateTo"
                        @load="stats['duration'] = $event"
                    />
                    <CardMenuNavigation
                        id="card-menus"
                        :project-id="projectId"
                        :installations="installations"
                        :from="dateFrom"
                        :to="dateTo"
                    />
                    <CardActivity
                        id="card-activity"
                        :project-id="projectId"
                        :installations="installations"
                        :from="dateFrom"
                        :to="dateTo"
                        :duration="duration"
                        @load="stats['activity'] = $event"
                    />
                    <CardTopFiches
                        id="card-top-fiches"
                        :project-id="projectId"
                        :installations="installations"
                        :from="dateFrom"
                        :to="dateTo"
                        :count="7"
                        @load="stats['fiches'] = $event"
                    />
                </div>
            </b-tab>
            <b-tab title="Comparateur">
                <div class="k-flex-col text-center">
                    <h3>En cours de développement</h3>
                    <div>
                        Cet outil offrira la possibilité mettre en comparaison
                        les statistiques sur deux périodes différentes.
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import CardTouches from "@/components/model/stats/CardTouches";
import CardTopFiches from "@/components/model/stats/CardTopFiches";
import CardSessions from "@/components/model/stats/CardSessions";
import CardDuration from "@/components/model/stats/CardDuration";
import CardMenuNavigation from "@/components/model/stats/CardMenuNavigation";
import CardActivity from "@/components/model/stats/CardActivity";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";
import { getDateFromToday } from "@/tools/dates";
import StatsReport from "@/model/StatsReport";
import notifier from "@/tools/notifier";

const TIMESPAN_START = 1;

const PRESETS = {
    week: {
        text: "des 7 derniers jours",
        offset: TIMESPAN_START + 6,
    },
    month: {
        text: "des 30 derniers jours",
        offset: TIMESPAN_START + 29,
    },
    semester: {
        text: "des 6 derniers mois",
        offset: TIMESPAN_START + 182,
    },
    year: {
        text: "des 12 derniers mois",
        offset: TIMESPAN_START + 364,
    },
};

export default {
    components: {
        CardTouches,
        CardTopFiches,
        CardSessions,
        CardDuration,
        CardMenuNavigation,
        CardActivity,
        DatePicker,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            configId: this.$route.params.configId,
            installations: [],
            periodSelected: "month",
            periodOptions: [
                ...Object.entries(PRESETS).reduce((acc, [key, val]) => {
                    acc.push({ value: key, text: val.text });
                    return acc;
                }, []),
                { value: "custom", text: "personnalisée" },
            ],
            periodCustom: [
                getDateFromToday(TIMESPAN_START + 150),
                getDateFromToday(TIMESPAN_START),
            ],
            stats: {},
        };
    },

    computed: {
        dateFrom() {
            if (this.periodSelected === "custom") {
                return this.periodCustom[0];
            } else {
                return getDateFromToday(PRESETS[this.periodSelected].offset);
            }
        },

        dateTo() {
            if (this.periodSelected === "custom") {
                return this.periodCustom[1];
            } else {
                return getDateFromToday(TIMESPAN_START);
            }
        },

        duration() {
            let diff =
                new Date(this.dateTo).getTime() -
                new Date(this.dateFrom).getTime();
            return Math.ceil(diff / (1000 * 3600 * 24)) + 1;
        },

        pageName() {
            if (this.installations.length > 1) {
                return "Toutes les installations";
            } else if (this.installations.length === 1) {
                return this.installations[0].name;
            } else {
                return "Chargement en cours";
            }
        },
    },

    methods: {
        async fetchInstallations() {
            const { installId } = this.$route.params;
            if (installId === "all") {
                this.installations = await this.$store.dispatch(
                    "application/getInstallations",
                    {
                        projectId: this.projectId,
                        configId: this.configId,
                    },
                );
            } else {
                this.installations = [
                    await this.$store.dispatch("project/getInstallation", {
                        projectId: this.projectId,
                        installationId: installId,
                    }),
                ];
            }
        },

        async exportAsXLSX() {
            try {
                await StatsReport.createXLSX({
                    from: this.dateFrom,
                    to: this.dateTo,
                    installations: this.installations,
                    stats: this.stats,
                });
            } catch (error) {
                notifier.error("Échec de l'export Excel");
            }
        },
    },

    async beforeMount() {
        await this.fetchInstallations();
    },
};
</script>

<style lang="scss">
#tab-consult-grid {
    display: grid;
    grid-gap: 10px;

    & > * {
        min-width: 0;
        min-height: 0;
    }

    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
        "activity"
        "touches"
        "sessions"
        "duration"
        "menus"
        "fiches";
}

@media (min-width: 1024px) {
    #tab-consult-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, auto);
        grid-template-areas:
            "activity touches"
            "activity sessions"
            "activity duration"
            "menus menus"
            "fiches fiches";
    }
}

@media (min-width: 1600px) {
    #tab-consult-grid {
        grid-template-columns: 0.8fr 1fr 1fr 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
            "activity touches sessions duration"
            "activity menus menus fiches";
    }
}

#card-touches {
    grid-area: touches;
}

#card-sessions {
    grid-area: sessions;
}

#card-duration {
    grid-area: duration;
}

#card-top-fiches {
    grid-area: fiches;
}

#card-menus {
    grid-area: menus;
}

#card-activity {
    grid-area: activity;
}
</style>
